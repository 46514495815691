import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; {new Date().getFullYear()} John C.C. Fong. All rights reserved.</p>
      <ul>
        <li><a href="https://linkedin.com/in/john-cc-fong-843472b6" target="_blank" rel="noreferrer">LinkedIn</a></li>
      </ul>
    </footer>
  );
};

export default Footer;