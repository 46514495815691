import React from 'react';
import './about.css';

const About = () => {
  return (
    <section className="about">
      <h2>簡介</h2>
      <p>你好，感謝您瀏覽我的網站。</p>
      <p>我是方志莊，畢業於密德薩斯大學電腦網絡系，榮獲一等榮譽。擁有豐富的雲端開發和管理服務經驗，涵蓋基礎設施、網絡、Windows 及 Linux 平台，以及多種程式設計語言。曾在國泰航空有限公司、匯豐銀行等多間大型企業中擔任要職。</p>
      <p>以下是我的個人網站，我使用 AI 工具設計並整合了多種程式設計語言，以分享我的技術經驗和知識。希望這個網站能夠讓大家更了解我。</p>
      <p>在閒暇時，我喜歡出去運動，保持身體健康和活潑，讓自己擁有足夠的精力和創造力，實現夢想和目標，並擁抱生命中的每一個瞬間。</p>
      <p className="strengths">努力不懈、不斷學習、適應能力強、專注力高、開朗樂觀。</p>
      <p className="weaknesses">程式設計能力有待提高，但我不畏懼，挑戰自我，造就成功，我能建立以下網站。</p>
      <p className="motto">口號：「DREAM BIG, NEVER QUIT」</p>
    </section>
  );
};

export default About;