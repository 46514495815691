// Projects.js
import React from 'react';
import { Link } from 'react-router-dom';
import './projects.css';

function Projects() {
  const projects = [
    {
      id: 1,
      title: '舉例1) Hub & Spoke Architecture and Landing Zones on Azure Cloud',
      description: '原因 : 由於他們只為了專案開發，忽略了雲端架構的設計。因此它們只有用一個基本訂閱，將所有資源集中在一起。如果這個訂閱發生故障，客戶將無法連接。因此，本專案能夠幫助公司擁有擴展性、安全性、易於管理的優點，使公司資源獲得系統化的分配和管理。此外，採用 Hub and Spoke 架構和 Landing Zone 模式還能夠帶來額外的優點，包括提高資源利用率、簡化網絡管理、增強安全性和降低成本等。',
      technologies: ['Azure 雲端', 'Hub and Spoke 架構', 'Landing zone', 'Azure AD'],
      duration: '9 個月',
      role: 'Technical Consulant',
      image: 'Hub and spoken with Landing zone.jpg', // add image property
    },
    {
      id: 2,
      title: '舉例2) Azure ExpressRoute and Site-to-Site VPN connection on Azure Cloud',
      description: '原因 : 由於公司所有儲存或虛擬機器都存放在Azure Cloud上，並在on-premise環境中經過公眾網路存取，導致數據不安全、有被洩露的風險，因此提出專案，使用Azure ExpressRoute實現高帶寬、私密的連接，並使用Site-to-Site VPN連接作為備份，以確保數據安全。',
      technologies: ['Azure 雲端', 'ExpressRoute', 'Site-to-Site VPN'],
      duration: '4 months',
      role: 'Technical Consulant',
      image: 'Azure ExpressRoute and site to site vpn connection.jpg', // add image property
    },
    {
      id: 3,
      title: '舉例3) Azure Static Web Apps with Github Actions on Azure cloud',
      description: '原因：近年來，由於網站伺服器搬遷到雲端，程式員要求將網站建置在 Azure Cloud 上，並要求實行 CI/CD。因此提出了相關專案，將網站部署在 Azure Cloud上，同時實施 CI/CD。',
      technologies: ['Azure 雲端', 'Azure Static Web Apps', 'Github Actions'],
      duration: '3 months',
      role: 'Technical Consulant',
      image: 'Azure static web apps with Github.jpg', // add image property
    },
  ];

  return (
    <section>
      <h2>專案經驗：以下三個實際案例是我的精選，說明我的解決方案和成果。</h2>
      <ul>
        {projects.map((project) => (
          <li key={project.id}>
            <h3>{project.title}</h3>
            <p>{project.description}</p>
            <Link to={`/project/${project.id}`}>查看項目</Link>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default Projects;