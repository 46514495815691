import React from 'react';
import './experience.css'

const Experience = () => {
  return (
    <section className="experience">
      <h2>工作經驗</h2>
      <ul>
        <li>
          <div className="experience-item">
            <h3><a href="https://www.hkbroadband.com/" target="_blank" rel="noopener noreferrer">Technical Consultant，香港寬頻</a></h3>
            <span className="duration">2021年11月 - 2023年6月 (1年7個月)</span>
            <p>專注於雲端開發和管理服務，使用Azure portal和Azure CLI工具。</p>
            <p>與其他部門合作推動項目達到目標和成果，同时專門幫助客戶提供專業知識。</p>
          </div>
        </li>
        <li>
          <div className="experience-item">
            <h3><a href="https://www.hsbc.com/" target="_blank" rel="noopener noreferrer">Network Engineer，匯豐銀行</a></h3>
            <span className="duration">2019年5月 - 2021年5月 (2年)</span>
            <p>專注於網絡安全和網絡管理，使用防火牆和網絡設備。</p>
            <p>分析及解決網絡問題，與雲端部門及其他團隊合作推動項目，實現目標和成果。</p>
          </div>
        </li>
        <li>
          <div className="experience-item">
            <h3><a href="https://www.dimensiondata.com/" target="_blank" rel="noopener noreferrer">Network Engineer，Dimension Data</a></h3>
            <span className="duration">2018年10月 - 2019年5月 (7個月)</span>
            <p>專注於網路開發，使用網路設備為客戶建設網路。</p>
            <p>分析與解決網絡問題，協助其他部門合作推動項目達到目標和成果。</p>
          </div>
        </li>
        <li>
          <div className="experience-item">
            <h3><a href="https://macroview.com/" target="_blank" rel="noopener noreferrer">Network Engineer，Macroview Telecom Limited</a></h3>
            <span className="duration">2018年8月 - 2018年10月 (2個月) 專案</span>
            <p>專注於設定電話語音設備和測試，使用思科Voice Over IP。</p>
            <p>我們的團隊負責分析及解決網絡問題，以便其他部門順暢地使用IP電話。</p>
          </div>
        </li>
        <li>
          <div className="experience-item">
            <h3><a href="https://www.hkt.com/" target="_blank" rel="noopener noreferrer">Network Engineer，香港電訊有限公司</a></h3>
            <span className="duration">2015年12月 - 2017年8月 (1年8個月)</span>
            <p>專注於網路開發，使用網路設備為客戶建設網路。</p>
            <p>分析與解決網絡問題，協助其他部門合作推動項目達到目標和成果。</p>
          </div>
        </li>
        <li>
          <div className="experience-item">
            <h3><a href="https://www.cathaypacific.com/" target="_blank" rel="noopener noreferrer">Network Engineer Trainee，國泰航空有限公司</a></h3>
            <span className="duration">2014年8月 - 2015年6月 (11個月)</span>
            <p>集中於實習工作和體會公司的文化。</p>
            <p>協助同事解決簡單工作。</p>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default Experience;