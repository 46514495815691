import React from 'react';
import './skills.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTerminal } from '@fortawesome/free-solid-svg-icons';
import { faCloud } from '@fortawesome/free-solid-svg-icons';
import { faShield } from '@fortawesome/free-solid-svg-icons';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { faHtml5 } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faJs } from '@fortawesome/free-brands-svg-icons';
import { faNodeJs } from '@fortawesome/free-brands-svg-icons';
import { faReact } from '@fortawesome/free-brands-svg-icons';

const Skills = () => {
  return (
    <section className="skills">
      <h2>技能</h2>
      <ul>
        <li><FontAwesomeIcon icon={faTerminal} /> Azure CLI</li>
        <li><FontAwesomeIcon icon={faCloud} /> Azure Cloud</li>
        <li><FontAwesomeIcon icon={faCloud} /> Aws Cloud</li>
        <li><FontAwesomeIcon icon={faCode} /> Bash</li>
        <li><FontAwesomeIcon icon={faCode} /> Bootstrap</li>
        <li><FontAwesomeIcon icon={faHtml5} /> CSS3</li>
        <li><FontAwesomeIcon icon={faCode} /> Express</li>
        <li><FontAwesomeIcon icon={faShield} /> Firewall</li>
        <li><FontAwesomeIcon icon={faGithub} /> GitHub</li>
        <li><FontAwesomeIcon icon={faHtml5} /> HTML5</li>
        <li><FontAwesomeIcon icon={faCloud} /> Infrastructure</li>
        <li><FontAwesomeIcon icon={faJs} /> JavaScript</li>
        <li><FontAwesomeIcon icon={faCloud} /> Network</li>
        <li><FontAwesomeIcon icon={faNodeJs} /> Node.js</li>
        <li><FontAwesomeIcon icon={faReact} /> React</li>
      </ul>
    </section>
  );
};

export default Skills;