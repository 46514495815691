import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './projectdetail.css';

function ProjectDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const projects = [
    {
      id: 1,
      title: '專案1: Hub & Spoke Architecture and Landing Zones on Azure Cloud',
      description: '<b>原因 :</b> 由於他們只為了專案開發，忽略了雲端架構的設計。因此它們只有用一個基本訂閱，將所有資源集中在一起。如果這個訂閱發生故障，客戶將無法連接。因此，本專案能夠幫助公司擁有擴展性、安全性、易於管理的優點，使公司資源獲得系統化的分配和管理。此外，採用 Hub and Spoke 架構和 Landing Zone 模式還能夠帶來額外的優點，包括提高資源利用率、簡化網絡管理、增強安全性和降低成本等。圖片來自Microsoft Azure。',
      solution: [
        '我們使用 Hub and Spoke 架構和 Landing Zone 模式，將資源分配到多個訂閱中，提高了系統的可擴展性和安全性。如下:',
        '步驟 1: 規劃和設計',
        '定義商業需求，並確定需要 Hub 和 Spoke 架構與 Landing Zones。',
        '確定項目的範圍，包括訂閱數量、資源和用戶數。',
        '設計 Hub 和 Spoke 架構，包括選擇 Azure 服務和資源。',

        '步驟 2: 創建 Hub',
        '創建新的 Azure 訂閱用於 Hub。',
        '設置 Azure AD 並配置安全設定，包括多因素身份驗證和條件存取。',
        '創建虛擬網路 (VNet) 用於 Hub 並配置子網路、路由和安全群組。',

        '步驟 3: 創建 Spokes',
        '創建新的 Azure 訂閱用於每個 Spoke。',
        '設置 Azure AD 並配置安全設定，包括多因素身份驗證和條件存取。',
        '創建 VNet 用於每個 Spoke 並配置子網路、路由和安全群組。',

        '步驟 4: 建立連接',
        '設置 Hub 和 Spokes 之間的對等連接使用 Azure 虛擬網路對等連接。',
        '配置路由和安全設定以允許流量在 Hub 和 Spokes 之間流動。',

        '步驟 5: 實施 Landing Zones',
        '在每個 Spoke 訂閱中創建 Landing Zone 使用 Azure Landing Zones。',
        '配置 Landing Zone 以提供標準化和安全的環境資源。',

        '步驟 6: 部署資源',
        '部署資源，例如虛擬機器、存儲和資料庫，到 Landing Zones。',
        '配置監控和日誌記錄以確保安全和合規。',

        '步驟 7: 測試和驗證',
        '測試 Hub 和 Spoke 架構與 Landing Zones，以確保滿足商業需求。',
        '驗證架構的安全和合規。',

        '步驟 8: 部署到生產',
        '部署 Hub 和 Spoke 架構與 Landing Zones 到生產。',
        '監控和維護架構以確保持續安全和合規。',
      ],
      technologies: ['Azure 雲端', 'Hub and Spoke 架構', 'Landing zone', 'Azure AD'],
      duration: '9 個月',
      role: 'Technical Consulant',
      image: 'Hub and spoken with Landing zone.jpg', // add image property
    },
    {
      id: 2,
      title: '專案2 : Azure ExpressRoute and Site-to-Site VPN connection on Azure Cloud',
      description: '<b>原因 :</b> 由於公司所有儲存或虛擬機器都存放在Azure Cloud上，並在on-premise環境中經過公眾網路存取，導致數據不安全、有被洩露的風險，因此提出專案，使用Azure ExpressRoute實現高帶寬、私密的連接，並使用Site-to-Site VPN連接作為備份，以確保數據安全。圖片來自Microsoft Azure。',
      solution: [
        '我們使用ExpressRoute，享有穩定頻寬、低延遲、高可靠性和高安全性等優點，並備有站對站 VPN 連接以應對故障。',
        'Azure ExpressRoute & Site-to-Site VPN 連接實現專案',
        '步驟 1: 實現 Azure ExpressRoute 連接',
        '申請 Azure ExpressRoute 服務，並設定連接參數。',
        '配置路由和安全設定以允許流量在 Azure 和 on-premise 環境之間流動。',

        '步驟 2: 設置 Site-to-Site VPN 連接作為備份',
        '設定 Site-to-Site VPN 連接參數，並配置路由和安全設定。',
        '確保 Site-to-Site VPN 連接可以在 Azure ExpressRoute 連接失敗時提供備份連接。',

        '步驟 3: 設置 on-premise 環境連接到 Azure Cloud',
        '設定 on-premise 防火牆環境的網路設定，以連接到 Azure Cloud。',
        '測試連接以確保 on-premise 環境可以連接到 Azure Cloud。',

        '步驟 4: 測試和驗證',
        '測試計畫:',
        '  * 連接測試: 測試 Azure ExpressRoute 連接和 Site-to-Site VPN 連接的連通性。',
        '  * 性能測試: 測試連接的帶寬和延遲。',
        '  * 安全測試: 測試連接的安全設定和防火牆規則。',
        '驗證連接的安全和合規。',

        '步驟 5: 部署到生產',
        '部署 Azure ExpressRoute 連接和 Site-to-Site VPN 連接到生產。',
        '監控和維護架構以確保持續安全和合規。'
      ],
      technologies: ['Azure 雲端', 'ExpressRoute', 'Site-to-Site VPN'],
      duration: '4 months',
      role: 'Technical Consulant',
      image: 'Azure ExpressRoute and site to site vpn connection.jpg',
    },
    {
      id: 3,
      title: '舉例3) Azure Static Web Apps with Github Actions on Azure cloud',
      description: '<b>原因：</b> 近年來，由於網站伺服器搬遷到雲端，程式員要求將網站建置在 Azure Cloud 上，並要求實行 CI/CD。因此提出了相關專案，將網站部署在 Azure Cloud上，同時實施 CI/CD。圖片來自Microsoft Azure。',
      solution: [
        '我們使用 Azure Static Web Apps 將靜態網站托管於全球各地，讓全球客戶能夠快速連接，並透過 CI/CD 實現自動化部署，同時，我們也使用 GitHub Actions 進行版本控制。',
        'Azure Static Web Apps with Github Actions on Azure cloud',
        '步驟 1: 創建 Azure 靜態 Web 應用程式實例',
        '前往 Azure 入口網站並導航至 Azure 靜態 Web 應用程式服務。',
        '點擊 "創建" 並填寫所需資訊，例如應用程式名稱和資源組。',
        '選擇 "標準" 定價層並點擊 "創建" 以創建實例。',

        '步驟 2: 創建新的 GitHub 儲存庫',
        '前往 GitHub 並創建一個新的儲存庫供您的靜態 Web 應用程式使用。',
        '使用 `README.md` 檔案和 `.gitignore` 檔案初始化儲存庫。',

        '步驟 3: 配置 GitHub Actions',
        '在您的 GitHub 儲存庫中，前往 "Actions" 索引標籤並點擊 "New workflow"。',
        '選擇 "Azure 靜態 Web 應用程式" 模板並點擊 "Configure"。',
        '填寫所需資訊，例如 Azure 訂閱和資源組。',
        '配置工作流程以在 Azure 上建立和部署您的靜態 Web 應用程式。',

        '步驟 4: 創建 Azure 存儲帳戶',
        '前往 Azure 入口網站並導航至 Azure 存儲服務。',
        '點擊 "創建" 並填寫所需資訊，例如存儲帳戶名稱和資源組。',
        '選擇 "標準" 定價層並點擊 "創建" 以創建存儲帳戶。',

        '步驟 5: 配置 Azure 靜態 Web 應用程式以使用 Azure 存儲',
        '在 Azure 入口網站中，導航至您的 Azure 靜態 Web 應用程式實例。',
        '點擊 "Configuration" 然後點擊 "Storage"。',
        '選擇您在步驟 4 中創建的 Azure 存儲帳戶並點擊 "Save"。',

        '步驟 6: 部署您的靜態 Web 應用程式',
        '在您的 GitHub 儲存庫中，對您的靜態 Web 應用程式進行更改。',
        '提交並推送更改以觸發 GitHub Actions 工作流程。',
        '在工作流程完成後，您的靜態 Web 應用程式將部署到 Azure。',

        '步驟 7: 測試和驗證',
        '測試計畫:',
        '  * 連接測試: 測試 Azure 靜態 Web 應用程式的連通性。',
        '  * 性能測試: 測試連接的帶寬和延遲。',
        '  * 安全測試: 測試連接的安全設定和防火牆規則。',
        '驗證連接的安全和合規。',

        '步驟 8: 部署到生產',
        '部署 Azure 靜態 Web 應用程式到生產。',
        '監控和維護架構以確保持續安全和合規。'
      ],
      technologies: ['Azure 雲端', 'Azure Static Web Apps', 'Github Actions', 'Command Line'],
      duration: '3 months',
      role: 'Technical Consulant',
      image: 'Azure static web apps with Github.jpg', // add image property
    },
  ];

  const project = projects.find((project) => project.id === parseInt(id));

  if (!project) {
    return <h2>Project not found</h2>;
  }

  const handleReturnClick = () => {
    navigate('/projects'); // navigate to /projects
  };

  return (
    <section className="project-detail">
      <h2 className="project-title">{project.title}</h2>
      <img src={`/images/${project.image}`} alt={project.title} className="project-image" />
      <p className="project-description" dangerouslySetInnerHTML={{ __html: project.description }} />
      <h3 className="project-solution-title">解決方法：</h3>
      {project && project.solution && Array.isArray(project.solution) ?
        project.solution.map((step, index) => (
          <p key={index} className="project-solution">
            {step.includes('步驟') ? <b>{step}</b> : step}
          </p>
        )) :
        <p className="project-solution">{project.solution}</p>
      }
      <h3 className="project-technologies-title">Technologies:</h3>
      <ul className="project-technologies-list">
        {project.technologies && project.technologies.map((tech) => (
          <li key={tech} className="project-technology-item">{tech}</li>
        ))}
      </ul>
      <h3 className="project-duration-title">Duration:</h3>
      <p className="project-duration">{project.duration}</p>
      <h3 className="project-role-title">Role:</h3>
      <p className="project-role">{project.role}</p>
      <button onClick={handleReturnClick} className="project-back-button">返回專案</button>
    </section>
  );
}

export default ProjectDetail;